import React from "react";
import "./Product.scss";
import Card from "../../component/card/Card";
import teradamai from "../../media/Product/Tera Damai";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import data from "./cardproduct.json";
import casajardin from "../../media/Product/Casajardin";
import viola from "../../media/Product/Viola";
import elora from "../../media/Product/Elora";
import nismara from "../../media/Product/Nismara";
import fremont from "../../media/Product/Fremont";
import nishi from "../../media/Product/Nishi";
import lavesh from "../../media/Product/Lavesh";
import jasmia from "../../media/Product/Jasmia";
import rukotera from "../../media/Ruko/Ruko Tera Damai";
import rukolavanya from "../../media/Ruko/Ruko Lavanya";
import rukoscg from "../../media/Ruko/Ruko SCG";
import thompson from "../../media/Product/thompson/thompson";
import Cardruko from "../../component/card/Cardruko";
import albasia from "../../media/Product/albasia/albasia";
import kana from "../../media/Product/kana/kana";
import hoshi from "../../media/Product/hoshi/hoshi";

const imageMap = {
  teradamai,
  casajardin,
  viola,
  elora,
  nismara,
  thompson,
  fremont,
  nishi,
  lavesh,
  jasmia,
  rukotera,
  rukolavanya,
  rukoscg,
  kana,
  albasia,
  hoshi,
};

const Product = () => {
  return (
    <div className="productcontainer">
      <div className="c">
        <div id="rumah" className="producttitle">
          OUR HOME
        </div>
        <div className="productcard">
          {data.cards.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Card
                key={index}
                carousel={imageMap[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                harga={card.harga}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink}
                iconcard={faBed} // Pass the WhatsApp link
              />
            );
          })}
        </div>
        <div className="productcard1">
          {data.cards1.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Card
                key={index}
                carousel={imageMap[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                harga={card.harga}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink}
                iconcard={faBed} // Pass the WhatsApp link
              />
            );
          })}
        </div>
        <div id="ruko" className="producttitle">
          Commercial's Product
        </div>
        <div className="productcard2">
          {data.ruko.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Cardruko
                key={index}
                carousel={imageMap[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                harga={card.harga}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink}
                iconcard={faBed} // Pass the WhatsApp link
              />
            );
          })}
        </div>
        <hr className="divider" />
      </div>
    </div>
  );
};

export default Product;
